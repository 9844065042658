<template>
  <div>
    <b-modal
      id="sell-direct-stock"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      title="Create Order"

      title-class="red"
      modal-class="dd"
      hide-footer
      @close="onCancel"
    >
      <b-form class="fixed-height-modal">
        <div
          class="existingClient"
          style="display:flex; flex-direction:row; width: 46%; justify-content: space-between;"
        >
          <b-button
            v-b-toggle.collapse-345
            class="m-1"
            variant="light"
          >
            <b-icon-arrows-expand />
          </b-button>
          <b-form-group
            style="width: 80%; padding-bottom: 10px;"
            :label="$t('SelectExistingClient')"
          >
            <div>
              <div class="black">
                <b-icon-search
                  v-if="searchClients == null"
                  class="search1"
                  style=" position: absolute; left: 5px; top: 10px"
                />
                <b-icon-x-circle
                  v-else-if="clientId != '00000000-0000-0000-0000-000000000000'"
                  class="search1"
                  style="position: absolute; margin-top: -6px; right: 165px; top: 17px; cursor: pointer"
                  @click="searchClients = null ,client= false, resetCustomerData()"
                />
                <input
                  v-model="searchClients"
                  style="width:250px;"
                  autocomplete="off"
                  type="text"
                  name="search-box"
                  :placeholder="$t('ClientName')"
                  :style="getFirstNames.length > 0 && noSearch ? 'border-radius: 8px 8px 8px 8px' : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'"
                  @keyup="inputChanged"
                  @keydown.down="onArrowDown"
                  @keydown.up="onArrowUp"
                >
              </div>
              <div
                v-if="getFirstNames.length > 0 && noSearch"
                ref="scrollContainer"
                class="dropdrop"
                :style="getFirstNames.length > 7 ? 'height: 210px' : 'height: auto'"
              >
                <div
                  v-for="(result, index) in getFirstNames"
                  :key="index"
                  ref="options"
                  style="cursor: pointer"
                  class="search-item"
                  :class="{ 'is-active': index === arrowCounter }"
                  @click="searchByOn(result.clientId); noSearch = false"
                >
                  <p style="margin: 0">
                    {{ result.nameToDisplay }}
                  </p>
                </div>
              </div>
            </div>
          </b-form-group>
          <b-button
            v-if="client == false && clientId == '00000000-0000-0000-0000-000000000000'"
            type="button"
            variant="none"
            class="buttonSubmit"
            style="width: 290px; height: 40px; margin-top: 27px;"
            @click="addNewClient"
          >
            {{ $t(`Addclient`) }}
          </b-button>
        </div>

        <section
          v-if="client == true || clientId != '00000000-0000-0000-0000-000000000000'"
          class="custom-modal__wrapper"
          style="border-width: 3px"
        >
          <b-tabs>
            <b-tab
              :title="$t('PersonalInfo')"
              active
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("PersonalInfo") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="$t('Salutation')">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.salutation.$model"
                      :options="salutation"
                      required
                      :placeholder="$t('SelectSalutation')"
                      :state="validateState('salutation')"
                      aria-describedby="input-1-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.salutation == null && buttonSubmit == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('FirstName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.firstName.$model"
                      :state="validateState('firstName')"
                      placeholder="e.g. Michael"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('LastName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.lastname.$model"
                      :state="validateState('lastname')"
                      placeholder="e.g. Goodman"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="hello">

                  <b-form-group :label="`${$t('Gender')}`">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.gender.$model"
                      :options="[$t('Male'), $t('Female')]"
                      :placeholder="$t('SelectGender')"
                      required
                      :state="validateState('gender')"
                      aria-describedby="input-5-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.gender == null && buttonSubmit == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('Email')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.email.$model"
                      :state="validateState('email')"
                      placeholder="e.g. example@digitbusiness.ch"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{
                        $t('Must_be_email_format')
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('PhoneNumber')}`">
                    <vue-phone-number-input
                      v-model="staticForm.ordererDto.telephoneNumber"
                      :default-country-code="staticForm.ordererDto.countryCode"
                      :preferred-countries="['CH', 'DE', 'AL']"
                      :error="isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false"
                      @update="getFormattedNumber"
                    />
                  </b-form-group>
                </div>

                <b-form-group :label="`${$t('OrderType')}`">
                  <b-form-select
                    v-model="selectedOrderType"
                    dropup
                    :options="transformOrderTypes"
                    required
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        width: 67%;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                  <p
                    v-if="!selectedOrderType && buttonSubmit"
                    style="color:red;"
                  >{{ $t('This_is_a_required_field') }}</p>
                </b-form-group>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('BillingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("BillingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="`${$t('Street')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.street"
                      placeholder="e.g. Rexhep Maja"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('HouseNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.houseNumber"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="e.g. 30"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback"> {{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('DoorNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.doorNumber"
                      type="number"
                      placeholder="e.g. 50"
                      step="any"
                      min="0"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="hello">

                  <b-form-group :label="`${$t('PostalCode')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.postalCode"
                      min="0"
                      placeholder="e.g. 10000"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('City')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.city"
                      placeholder="e.g. Prishtinë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('Country')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.country"
                      placeholder="e.g. Kosovë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('ShippingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("ShippingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div>
                  <div
                    class="hello"
                    style="padding-top: 20px"
                  >
                    <b-form-group :label="`${$t('Street')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.street"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Rexhep Maja"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback>{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('HouseNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.houseNumber"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 30"
                        type="number"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('DoorNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.doorNumber"
                        :disabled="pickUpInStore"
                        type="number"
                        placeholder="e.g. 50"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="hello">

                    <b-form-group :label="`${$t('PostalCode')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.postalCode"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 10000"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('City')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.city"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Prishtinë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('Country')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.country"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Kosovë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <span
                    class="duplicate-material"
                    @click="duplicateBillingAddress(), resetPickUpInStore()"
                  >
                    {{ $t('SameAsBillingAddress') }}
                  </span>
                </div>
                <p>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="pickUpInStore"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                    @input="resetShippingAddress()"
                  >
                    {{ $t('GetInStore') }}
                  </b-form-checkbox>
                </p>
              </b-collapse>
            </b-tab>
          </b-tabs>
        </section>
        <section
          v-if="buttonSubmit && !client && clientId == '00000000-0000-0000-0000-000000000000'"
          class="custom-modal__wrapper"
          style="border-width: 3px;margin-top: 10px;flex-direction: column;padding-right: 23px;"
        >
          <div style="padding-left:20px;">
            <p
              style="color:red; margin-bottom: 0px;"
            >
              {{ $t('EmptyClient') }}
            </p>
          </div>
        </section>
        <section
          v-if="buttonSubmit && materials.length == 0"
          class="custom-modal__wrapper"
          style="border-width: 3px;margin-top: 10px;flex-direction: column;padding-right: 23px;"
        >
          <div style="padding-left:20px;">
            <p
              style="color:red; margin-bottom: 0px;"
            >
              {{ $t('EmptyArticle') }}
            </p>
          </div>
        </section>
        <section
          v-for="(item,index) in materials"
          :key="index"
          class="custom-modal__wrapper"
          style="border-width: 3px;margin-top: 10px;flex-direction: column;padding-right: 23px;"
        >
          <div style="display: inline-flex; align-items: baseline">
            <h4
              :key="index"
              class="headline1"
            >
              {{ item.articleNumber }}
            </h4>
            <div
              class="inside-actions"
              @click="removeMaterial(index)"
            >
              <b-icon-trash />
            </div>
          </div>
          <div style="display: flex; width: 100%; border: 1px solid lightgray; border-radius: 5px">
            <div style="display: flex; width: 28%; flex-direction: column;">
              <b-form-group
                :label="`${$t('Price')}:`"
                style="display: inline-block; background: white ; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 100%;"
              >
                <div style="display: flex; justify-content: flex-start; align-items: center;flex-direction: column;">

                  <div style="display: flex;">
                    <b-input
                      v-model.number="item.price"
                      type="number"
                      style="width: 90%; margin-right: 5px;"
                    />
                    <b-form-select
                      v-model="item.currency "
                      dropup
                      required
                      :options="currencies"
                      aria-placeholder="Select order type"
                      aria-describedby="input-1-live-feedback"
                      style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                    />
                  </div>
                </div>
                <p
                  v-if="item.discountType == '%' && (item.currency != '$' && item.currency != '€' && item.currency != 'CHF') "
                  style="margin-bottom:0px"
                >
                  {{ $t('SelectValute') }}
                </p>
              </b-form-group>
              <b-form-group
                :label="$t('PaidPrice')"
                style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin: 10px 0px 0px 0px; width: 100%;"
              >
                <div style="display: flex; justify-content: flex-start; align-items: center;flex-direction:column; width: 100%">
                  <div style="display:flex;">
                    <b-input
                      v-model.number="item.paidAmount"
                      type="number"
                      :disabled="item.price == 0 || priceWithDiscount[index].discountedPrice == 0"
                      style="width: 90%; margin-right: 5px;"
                    />
                    <b-form-select
                      v-model="item.currency "
                      dropup
                      required
                      :options="currencies"
                      aria-placeholder="Select order type"
                      aria-describedby="input-1-live-feedback"
                      style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                    />
                  </div>
                </div>
                <p
                  v-if="item.discountType == '%' && (item.currency != '$' && item.currency != '€' && item.currency != 'CHF') "
                  style="margin-bottom:0px"
                >
                  {{ $t('SelectValute') }}
                </p>
              </b-form-group>
              <b-form-group
                :label="$t('Notes')"
                style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin: 10px 0px 0px 0px; width: 100%;"
              >
                <div style="display: flex; justify-content: flex-start; flex-direction:column; width: 100%">
                  <div style="display:flex;">
                    <b-textarea
                      v-model="item.paidNotes"
                      style="width: 100%;"
                    />
                  </div>
                </div>
              </b-form-group>
              <div style="margin: 5%; display: flex">
                <p class="p-0 m-0 ml-50">
                  {{ $t('IncludeTax') }}
                  <toggle-button
                    :value="item.taxRate"
                    :sync="true"
                    color="#242F6E"
                    @change="item.taxRate = !item.taxRate"
                  />
                </p>
                <div
                  v-if="item.taxRate == true"
                  style=" margin-left: 10px;"
                >
                  <span>18%</span>
                </div>
              </div>
            </div>
            <div style="display: flex; flex-direction: row; margin-left:0px; justify-content:flex-start; height: 1%;">
              <b-button
                v-if="!item.showDiscountForm"
                type="button"
                size="sm"
                class="button"
                style="margin: 20px 20px 20px 0px; width: 280px; margin-top: 16.5%"
                @click="item.showDiscountForm = true"
              >
                <p style="margin: 0">
                  + {{ $t('AddDiscount') }}
                </p>
              </b-button>
              <b-form-group
                v-if="item.showDiscountForm"
                :label="`${$t('Discount')}:`"
                style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 30%; margin-right: 5px;margin-left: 10px;"
              >
                <div style="display: flex;justify-content: flex-start; align-items: center;">
                  <b-input
                    v-model.number="item.discount"
                    type="number"
                    default="0"
                    :disabled="item.price == 0"
                    style="width: 55%; ;margin-right: 5px;"
                  />
                  <b-form-select
                    v-model="item.discountType"
                    style="width: 50%; margin-right: 5px;height:38px; border-radius: 0.25rem; border: 1px solid #EAECF0"
                  >
                    <option value="$">
                      $
                    </option>
                    <option value="€">
                      €
                    </option>
                    <option value="CHF">
                      CHF
                    </option>
                    <option value="%">
                      %
                    </option>
                  </b-form-select>
                  <div>
                    <b-icon-trash
                      type="button"
                      size="sm"
                      style="background:white;color: black; height: 36px; width: 30px; padding: 5px; border-radius: 0.25rem;"
                      @click="item.showDiscountForm = false,resetDiscountedPrice(index) "
                    />
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                v-if="item.showDiscountForm"
                :label="`${$t('PriceDiscount')}:`"
                style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 30%;"
              >
                <div style="display:flex">
                  <b-input
                    v-model.number="priceWithDiscount[index].discountedPrice"
                    type="number"
                    :disabled="item.price == 0"
                    style="width: 90%; margin-right: 5px;"
                  />
                  <b-form-select
                    v-model="item.currency "
                    dropup
                    required
                    :options="currencies"
                    aria-placeholder="Select order type"
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                </div>
                <p
                  v-if="item.discountType == '%' && (item.currency != '$' && item.currency != '€' && item.currency != 'CHF') "
                  style="margin-bottom:0px; border: 1px solid #EAECF0"
                >
                  {{ $t('SelectValute') }}
                </p>
              </b-form-group>
            </div>
          </div>
        </section>
        <section
          class="custom-modal__wrapper"
          style="border-width: 3px;display: flex;flex-direction: column;margin-top: 15px;"
        >
          <label style="padding-left: 20px;padding-bottom: 10px"><p style="font-size: 24px;">{{ $t('OverviewOfProductsPrices') }}</p></label>
          <div style="display: flex; width: 97%; border: 1px solid lightgray; border-radius: 5px">
            <div style="display: flex; width: 100%; flex-direction: row;">
              <b-form-group
                :label="`${$t('TotalPrice')}:`"
                style="display: inline-block; background: white ; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 100%;"
              >
                <div style="display: flex; justify-content: flex-start;flex-direction: column;">

                  <div style="display: flex;">
                    <b-input
                      v-model.number="totalCost"
                      type="number"
                      disabled
                      style="width: 90%; margin-right: 5px;"
                    />
                    <b-form-select
                      v-model="currency"
                      dropup
                      disabled
                      required
                      :options="currencies"
                      aria-describedby="input-1-live-feedback"
                      style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: var(--bs-secondary-bg);;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                    />
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                :label="$t('TotalPaidPrice')"
                style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin: 10px 0px 0px 0px; width: 100%;"
              >
                <div style="display: flex; justify-content: flex-start;flex-direction:column; width: 100%">
                  <div style="display:flex;">
                    <b-input
                      v-model.number="totalPaidPrice"
                      disabled
                      type="number"
                      style="width: 90%; margin-right: 5px;"
                    />
                    <b-form-select
                      v-model="currency "
                      dropup
                      required
                      disabled
                      :options="currencies"
                      aria-describedby="input-1-live-feedback"
                      style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: var(--bs-secondary-bg);;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                    />
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </section>
      </b-form>
      <div style="display:flex;gap:10px;margin-top:15px">
        <AddFile
          ref="addFile1"
          :folder="'OrderPhotos'"
          :label-text="$t('UploadSketch')"
          :file-data-type="'Scetch'"
          :file-name="createFileDataDto"
        />
        <AddFile
          ref="addFile2"
          :folder="'OrderPhotos'"
          :label-text="$t('UploadContract')"
          :file-data-type="'Contract'"
          :file-name="createFileDataDto"
        />
        <AddFile
          ref="addFile3"
          :folder="'OrderPhotos'"
          :label-text="$t('OtherDocuments')"
          :file-data-type="'OtherDocument'"
          :file-name="createFileDataDto"
        />
      </div>
      <div style="display:flex;gap:10px;margin-top:15px">
        <b-form-group
          :label="$t('SelectAccount')"
        >
          <vue-select
            v-model="accountId"
            :options="getAccountDetails"
            required
            label="name"
            :reduce="(account) => account.accountId"
            :placeholder="$t('SelectAccount')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <b-form-group
          :label="$t('SelectSalesPerson')"
        >
          <vue-select
            v-model="roleId"
            :options="getUsersMultipleRoles"
            required
            label="fullName"
            :reduce="(account) => account.userID"
            :placeholder="$t('SelectAccount')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
      </div>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-top: 20px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; margin-top: 20px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, email,
} from 'vuelidate/lib/validators';
import moment from 'moment'
import AddFile from '@/modals/AddFile.vue'


export default {
  components: {
    AddFile,
  },
  mixins: [validationMixin],
  props: ['ordersArticles'],
  data() {
    return {
      createFileDataDto: [],
      accountId: null,
      roleId: null,
      materials: [],
      paidPrice: 0,
      discount: 0,
      includeTax: false,
      taxRate: 0,
      price: 0,
      currency: '€',
      currencies: ['€', '$', 'CHF'],
      showDiscountForm: false,
      discountType: '€',
      isSubmitClicked: false,
      isPhoneNumberValid: null,
      clientId: '00000000-0000-0000-0000-000000000000',
      selectedOrderType: null,
      buttonSubmit: false,
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
      noSearch: false,
      arrowCounter: 0,
      client: false,
      searchClients: null,
      error: {
        oType: '',
        duplicateError: '',
      },
      pickUpInStore: false,
      staticForm: {
        ordererDto: {
          salutation: null,
          firstName: '',
          lastname: '',
          email: '',
          gender: null,
          telephoneNumber: '',
          countryCode: '',
        },
        billingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
        shippingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
      },
    }
  },
  validations: {
    selectedOrderType: {
      required,
    },
    staticForm: {
      ordererDto: {
        salutation: {
          required,
        },
        firstName: {
          required,
          minLength: minLength(3),
        },
        lastname: {
          required,
          minLength: minLength(3),
        },
        email: {
          required,
          email,
        },
        gender: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getClients',
      'getOrderTypes',
      'getMeasermentsById',
      'getFirstNames',
      'getTotalItemsForClients',
      'getClient',
      'getLanguage',
      'getAccountDetails',
      'getUsersMultipleRoles',
      'getAccountDetails',
      'getUsersMultipleRoles']),
    transformOrderTypes() {
      const allOption = { value: null, text: `${this.$t('SelectOrderType')}`, orderType: 'null' };
      const orderTypes = this.getOrderTypes.map((order) => {
        return {
          value: order.orderType,
          text: order.orderType,
          orderType: order.orderType,
        }
      });
      return [allOption, ...orderTypes];
    },
    priceWithDiscount() {
      return this.materials.map((item) => {
        return {
          get discountedPrice() {
            if (item.discountType === '$' || item.discountType === '€' || item.discountType === 'CHF') {
              return item.price - item.discount;
            } if (item.discountType === '%') {
              if (item.price === 0) {
                return 0; // Avoid division by zero
              }
              const percentageDiscount = (item.discount / 100) * item.price;
              return item.price - percentageDiscount;
            }
            return 0;
          },
          set discountedPrice(value) {
            if (item.discountType === '$' || item.discountType === '€' || item.discountType === 'CHF') {
              item.discount = item.price - value;
            } else if (item.discountType === '%') {
              if (item.price === 0) {
                item.discount = 0; // Avoid division by zero
              } else {
                const percentageDiscount = (item.price - value) / (item.price * 100);
                item.discount = percentageDiscount;
              }
            }
          },
        };
      });
    },
    totalCost() {
      let total = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.priceWithDiscount) {
        if (item.discountedPrice) {
          total += item.discountedPrice;
        }
      }
      return `${total.toFixed(2)}`;
    },
    totalPaidPrice() {
      let total = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.materials) {
        if (item.paidAmount) {
          total += item.paidAmount;
        }
      }
      return `${total.toFixed(2)}`;
    },
  },
  watch: {
    priceWithDiscount: {
      handler(newMaterials) {
        newMaterials.forEach((material, index) => {
          this.$watch(
            () => { return material.discountedPrice },
            (newPrice) => {
              if (newPrice > this.materials[index].price) {
                this.$set(this.priceWithDiscount[index], 'discountedPrice', 0);
              }
              if (newPrice > this.materials[index].paidAmount) {
                this.$set(this.materials[index], 'paidAmount', 0);
              }
            },
          );
        });
      },
    },
    materials: {
      handler(newMaterials) {
        newMaterials.forEach((material, index) => {
          this.$watch(
            () => { return material.price },
            (newPrice) => {
              if (newPrice < material.discount) {
                this.$set(this.materials[index], 'discount', 0);
              }
            },
          );

          this.$watch(
            () => { return material.discount },
            (newDiscount) => {
              if (material.discountType === '$' || material.discountType === '€' || material.discountType === 'CHF') {
                if (newDiscount > material.price) {
                  this.$set(this.materials[index], 'discount', 0);
                  this.$set(this.materials[index], 'paidAmount', 0);
                }
              } else if (material.discountType === '%') {
                if (newDiscount > 100) {
                  this.$set(this.materials[index], 'discount', 0);
                  this.$set(this.materials[index], 'paidAmount', 0);
                }
              }
            },
          );
          this.$watch(
            () => { return material.paidAmount },
            (paidValue) => {
              if (paidValue > material.price || paidValue > this.priceWithDiscount[index].discountedPrice) {
                this.$set(this.materials[index], 'paidAmount', 0);
              }
            },
          );


          this.$watch(
            () => { return material.discountedPrice },
            (newDiscountedPrice) => {
              if (newDiscountedPrice > material.price) {
                this.$set(this.materials[index], 'price', newDiscountedPrice);
                this.$set(this.materials[index], 'discount', 0);
              }
            },
          );

          this.$watch(
            () => { return material.currency },
            (newCurrency) => {
              if ((newCurrency === '$' || newCurrency === '€' || newCurrency === 'CHF') && material.discountType != '%') {
                this.$set(this.materials[index], 'discountType', newCurrency);
                this.currency = newCurrency
              }
            },
          );
          this.$watch(
            () => { return material.taxRate },
            (newValue) => {
              if (newValue == true) {
                this.$set(this.materials[index], 'taxRate', 18);
              } else if (newValue == false || newValue == 0) {
                this.$set(this.materials[index], 'taxRate', 0);
              }
            },
          );

          this.$watch(
            () => { return material.discountType },
            (newDiscountType) => {
              if (newDiscountType === '$' || newDiscountType === '€' || newDiscountType === 'CHF') {
                this.$set(this.materials[index], 'currency', newDiscountType);
                this.currency = newDiscountType
              } else if (newDiscountType === '%') {
                this.$set(this.materials[index], 'currency', '€');
                this.currency = '€'
              }
              this.$set(this.materials[index], 'discount', 0);
            },
          );
        });
      },
      deep: true,
      immediate: true,
    },
    ordersArticles(value) {
      this.materials = value
    },
    searchClients(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == null) {
          this.clearCustomMeasurements();
          this.removeLists();
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.firstNames(value);
          this.noSearch = true
        }
      }, 500);
    },
    pickUpInStore(value) {
      if (value == true) {
        this.staticForm.shippingAddressDto.street = '';
        this.staticForm.shippingAddressDto.houseNumber = '';
        this.staticForm.shippingAddressDto.doorNumber = '';
        this.staticForm.shippingAddressDto.postalCode = '';
        this.staticForm.shippingAddressDto.city = '';
        this.staticForm.shippingAddressDto.country = '';
      }
    },
    selectedOrderType(value) {
      if (value !== null) {
        this.error.oType = ''
      }
    },
  },
  methods: {
    ...mapActions([
      'usersByMultipleRoles', 'resetCustomOrders', 'clearCustomMeasurements', 'loadOrderTypes', 'editClient', 'getClientById', 'getClientsPagination', 'getClientById', 'firstNames', 'resetSearchClients', 'loadMeasermentsById',
    ]),
    resetDiscountedPrice(index) {
      this.materials[index].discount = 0;
    },
    removeMaterial(index) {
      this.materials.splice(index, 1)
    },
    resetPickUpInStore() {
      this.pickUpInStore = false
    },
    addNewClient() {
      this.client = true;
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.staticForm.ordererDto.telephoneNumber = n.nationalNumber
        this.staticForm.ordererDto.countryCode = n.countryCode
      } else {
        this.staticForm.ordererDto.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFirstNames[this.arrowCounter].clientId)
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.staticForm.ordererDto[name];
      return $dirty ? !$error : null;
    },
    async searchByOn(value) {
      this.noSearch = false
      const clientData = await this.getClientById({
        clientId: value,
        successCallback: () => {
        },
      })
      this.searchClients = `${clientData.firstName} ${clientData.lastName}`
      this.clientId = value
      this.staticForm.ordererDto.salutation = clientData.salutation
      this.staticForm.ordererDto.firstName = clientData.firstName
      this.staticForm.ordererDto.lastname = clientData.lastName
      this.staticForm.ordererDto.gender = clientData.gender
      this.staticForm.ordererDto.email = clientData.email
      this.staticForm.ordererDto.telephoneNumber = clientData.telephoneNumber
      this.staticForm.ordererDto.countryCode = clientData.countryCode
      this.staticForm.ordererDto.taxRate = clientData.taxRate
      this.staticForm.billingAddressDto.street = clientData.billingAddressDto.street
      this.staticForm.billingAddressDto.houseNumber = clientData.billingAddressDto.houseNumber
      this.staticForm.billingAddressDto.doorNumber = clientData.billingAddressDto.doorNumber
      this.staticForm.billingAddressDto.postalCode = clientData.billingAddressDto.postalCode
      this.staticForm.billingAddressDto.city = clientData.billingAddressDto.city
      this.staticForm.billingAddressDto.country = clientData.billingAddressDto.country
      this.staticForm.shippingAddressDto.street = clientData.shippingAddressDto.street
      this.staticForm.shippingAddressDto.houseNumber = clientData.shippingAddressDto.houseNumber
      this.staticForm.shippingAddressDto.doorNumber = clientData.shippingAddressDto.doorNumber
      this.staticForm.shippingAddressDto.postalCode = clientData.shippingAddressDto.postalCode
      this.staticForm.shippingAddressDto.city = clientData.shippingAddressDto.city
      this.staticForm.shippingAddressDto.country = clientData.shippingAddressDto.country
      this.body = clientData
    },
    async onSubmit() {
      this.buttonSubmit = true

      try {
        this.$v.staticForm.ordererDto.$touch();
        if (this.$v.staticForm.ordererDto.$anyError) {
          return;
        }
        if (this.materials.length == 0 && this.buttonSubmit) {
          return;
        }
        if (!this.selectedOrderType && this.buttonSubmit) {
          return;
        }
        await this.$refs.addFile1.uploadFile();
        await this.$refs.addFile2.uploadFile();
        await this.$refs.addFile3.uploadFile();

        const materialet = this.materials.map((item) => {
          return {
            price: item.price,
            discount: item.discount,
            articleNumber: item.articleNumber,
            paidAmount: item.paidAmount,
            currency: item.currency,
            priceNotes: item.priceNotes,
            paidNotes: item.paidNotes,
            taxRate: item.taxRate,
            size: null,
            stockLocationId: item.locationId,
          }
        })
        const objekti = {
          ordererDto: this.staticForm.ordererDto,
          billingAddressDto: this.staticForm.billingAddressDto,
          shippingAddressDto: this.staticForm.shippingAddressDto,
          pickUpDate: moment().format('YYYY-MM-DD'),
          description: null,
          pickUpInStore: this.pickUpInStore,
          createFileDataDto: this.createFileDataDto,
          orderItemInStoreDtos: materialet,
          accountId: this.accountId,
          salesUserId: this.roleId,
          clientId: this.clientId,
          orderType: this.selectedOrderType,
          orderNumber: null,
        }
        this.$emit('sellOrderDirect', objekti);
        this.$refs.modal.hide();
        setTimeout(() => {
          this.$v.$reset();
          this.$emit('resetMaterials')
          this.resetForm();
        }, 0);
      } catch (error) {
        console.log(error)
      }
    },
    onCancel() {
      this.$emit('resetMaterials')
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm();
      }, 10);
    },
    removeLists() {
      this.noSearch = false;
      this.resetSearchClients();
    },
    resetForm() {
      this.buttonSubmit = false
      this.createFileDataDto = []
      this.orderNumber = '';
      this.client = false;
      this.discountType = '€';
      this.discountType = '€';
      this.materials = []
      this.searchClients = null
      this.clientId = '00000000-0000-0000-0000-000000000000'
      this.error.oType = ''
      this.resetCustomerData();
      this.isSubmitClicked = false;
      this.description = '';
      this.accountId = null
      this.roleId = null
    },
    resetCustomerData() {
      this.staticForm.ordererDto.salutation = null;
      this.staticForm.ordererDto.firstName = '';
      this.staticForm.ordererDto.lastname = '';
      this.staticForm.ordererDto.email = '';
      this.staticForm.ordererDto.gender = null;
      this.staticForm.ordererDto.telephoneNumber = '';

      this.staticForm.billingAddressDto.street = '';
      this.staticForm.billingAddressDto.houseNumber = '';
      this.staticForm.billingAddressDto.doorNumber = '';
      this.staticForm.billingAddressDto.postalCode = '';
      this.staticForm.billingAddressDto.city = '';
      this.staticForm.billingAddressDto.country = '';

      this.staticForm.shippingAddressDto.street = '';
      this.staticForm.shippingAddressDto.houseNumber = '';
      this.staticForm.shippingAddressDto.doorNumber = '';
      this.staticForm.shippingAddressDto.postalCode = '';
      this.staticForm.shippingAddressDto.city = '';
      this.staticForm.shippingAddressDto.country = '';

      this.pickUpInStore = false;
      this.selectedOrderType = null;
      this.isPhoneNumberValid = null;
      this.searchClients = null
      this.clientId = '00000000-0000-0000-0000-000000000000'
    },
  },
}

</script>

<style lang="scss" scoped>
.headline1 {
  padding: 5px 10px;
  background-color: #FF274F;
  color: $digit-white;
  border-radius: 8px;
}

.modal-header{
  font-size: 25px !important;
}

.fixed-height-modal[data-v-094d39fb] {
    height: 100%;
    overflow-y: auto;
    padding-right: 1rem;
}
textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 1090px;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: 49%;
}
.price-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1%
}
.price-text {
  margin-left: 0px;
  font-size: 18px;
  // font-weight: bold;
  color: black;
  border-radius: 10px;
}
.search1 {
    padding: 0px;
  }
.black {
  position: relative;
}
.black input {
  height: 38px;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
z-index: 2;
box-shadow: none;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.headline {
  padding: 10px 10px;
  background-color: white;
  color: black;
  font-weight: bold;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}


.btn-con {
  display: flex;

  button {
    border: none;
    background: #FF274F;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}


@media only screen and (max-width: 1200px) {
  textarea.form-control[data-v-094d39fb] {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 745px;
  }
  .existingClient{
    width: 65% !important;
  }

  .btn-con {
    display: flex;

    button {
      border: none;
      background-color: #6c757d;
      ;
      color: $digit-white;
      border-radius: 0.25rem;
      padding: 3px 12px;
    }
  }

}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $base-color ;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

</style>
